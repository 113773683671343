@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700');

body {
  font-family: 'Montserrat', sans-serif;
  padding: 0;
  margin: 0;
}

/*---------------------Main Page------------------------*/

.section {
  transition: transform 1s, opacity 1s;
}

.section--hidden {
  opacity: 0;
  transform: translateY((8rem));
}

#home {
  width: 100%;
  height: 100vh;
  background-image: url('https://i.pinimg.com/originals/3b/8a/d2/3b8ad2c7b1be2caf24321c852103598a.jpg');
  background-size: cover;
  background-position: center;
  overflow: hidden;
  .header {
    display: inline-block;
    position: relative;
    width: 100%;
    .git {
      text-decoration: none;
      color: white;
      display: inline-block;
      font-size: 45px;
      float: left;
      position: relative;
      padding: 56px 0 0 70px;
    }
    nav {
      position: absolute;
      display: inline-block;
      right: 0;
      overflow: hidden;
      padding: 50px 80px 0;
      text-shadow: 2px 2px 1px rgba(0, 0, 0, 1);
      text-align: center;
      ul {
        padding: 0;
        li {
          display: inline-block;
          a {
            display: inline-block;
            text-decoration: none;
            color: white;
            font-size: 24px;
            padding-right: 50px;
          }
        }
      }
    }
  }
  .main {
    text-align: center;
    .heading {
      position: absolute;
      width: 100%;
      bottom: 20px;
      left: 0px;
      overflow: hidden;
      color: white;
      text-shadow: 2px 2px 1px rgba(0, 0, 0, 1);
      h1 {
        display: block;
        font-size: 50px;
        font-weight: 700;
        margin-bottom: 10px;
      }
      h4 {
        font-size: 30px;
        font-weight: 400;
      }
    }

    .git-2 {
      position: absolute;
      text-decoration: none;
      color: white;
      display: inline-block;
      font-size: 20px;
      position: relative;
      margin: 0 auto;
    }
  }

  @media only screen and (max-width: 431px) {
    .header nav ul li a {
      padding: 0 20px 0 0;
    }
  }

  @media only screen and (max-width: 700px) {
    .main .heading h1 {
      font-size: 40px;
    }
    .main .heading h4 {
      font-size: 26px;
    }
    .header nav a {
      font-size: 20px;
    }
  }

  @media only screen and (max-width: 850px) {
    .header .git {
      display: none;
    }
    .header nav {
      float: none;
      text-align: center;
      position: static;
      display: block;
    }
    .projects {
      padding: 0 !important;
    }
  }
  @media only screen and (min-width: 851px) {
    .heading .git-2 {
      display: none;
    }
  }

  @media only screen and (max-width: 1400px) {
    .header .git {
      padding: 55px 0px 0 60;
    }
    .header nav {
      padding: 50px 0px 0 0;
    }
  }
}

#skills {
  background-color: #ecf0f0;
  width: 100%;
  text-align: center;
  overflow: hidden;
  .page-title {
    margin-top: 40px;
    padding: 40px;
    i {
      font-size: 38px;
      padding-bottom: 30px;
      color: #0096e6;
    }
    h1 {
      color: #333333;
      font-size: 58px;
      font-weight: 700;
    }
  }

  .ribbon {
    img {
      width: 330px;
    }
    p {
      font-weight: 500;
      position: relative;
      color: white;
      top: -56px;
      margin: 0 auto;
    }
  }

  .programs {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    max-width: 1100px;
    margin: 0 auto;
    img {
      width: 84px;
      margin-top: 30px;
    }
    h4 {
      padding: 20px 0;
    }
    .html {
      width: 275px;
    }
    .css {
      width: 275px;
    }
    .java {
      width: 275px;
    }
    .next {
      width: 275px;
      
      img {
        width: 85px;
        border-radius: 50%;
      }
    }
    .jquery {
      width: 275px;
    }
    .react {
      width: 275px;
    }
    .photoshop {
      width: 275px;
    }
    .solidity {
      width: 275px;
    }
    .sketch {
      width: 275px;
    }
  }

  .work-ribbon {
    margin: 96px 0 30px;
    img {
      width: 330px;
    }
    p {
      font-weight: 500;
      position: relative;
      color: white;
      top: -55px;
      margin: 0 auto;
    }
  }

  .process {
    max-width: 1100px;
    margin: 50px auto;
    display: inline;
    img {
      width: 40px;
    }
    .discover {
      background-color: white;
      width: 110px;
      height: 90px;
      border-radius: 15%;
      padding: 20px 10px;
      margin: 0 20px;
      display: inline-block;
      p {
        margin-top: 15px;
        color: #0096e6;
        font-weight: 600;
      }
    }

    .idea {
      background-color: white;
      width: 110px;
      height: 90px;
      border-radius: 15%;
      padding: 20px 10px;
      margin: 0 20px;
      display: inline-block;
      p {
        margin-top: 15px;
        color: #0096e6;
        font-weight: 600;
      }
    }

    .design {
      background-color: white;
      width: 110px;
      height: 90px;
      border-radius: 15%;
      padding: 20px 10px;
      margin: 0 20px;
      display: inline-block;
      p {
        margin-top: 15px;
        color: #0096e6;
        font-weight: 600;
      }
    }

    .develop {
      background-color: white;
      width: 110px;
      height: 90px;
      border-radius: 15%;
      padding: 20px 10px;
      margin: 0 20px;
      display: inline-block;
      p {
        margin-top: 15px;
        color: #0096e6;
        font-weight: 600;
      }
    }

    .test {
      background-color: white;
      width: 110px;
      height: 90px;
      border-radius: 15%;
      padding: 20px 10px;
      margin: 0 20px;
      display: inline-block;
      p {
        margin-top: 15px;
        color: #0096e6;
        font-weight: 600;
      }
    }

    .launch {
      background-color: white;
      width: 110px;
      height: 90px;
      border-radius: 15%;
      padding: 20px 10px;
      margin: 0 20px;
      display: inline-block;
      p {
        margin-top: 15px;
        color: #0096e6;
        font-weight: 600;
      }
    }
  }

  .process-2 {
    max-width: 1100px;
    margin: 50px auto;
    display: inline;
    img {
      width: 40px;
    }
    .discover {
      background-color: white;
      width: 110px;
      height: 90px;
      border-radius: 15%;
      padding: 20px 10px;
      margin: 0 20px;
      display: inline-block;
      p {
        margin-top: 15px;
        color: #0096e6;
        font-weight: 600;
      }
    }

    .idea {
      background-color: white;
      width: 110px;
      height: 90px;
      border-radius: 15%;
      padding: 20px 10px;
      margin: 0 20px;
      display: inline-block;
      p {
        margin-top: 15px;
        color: #0096e6;
        font-weight: 600;
      }
    }

    .design {
      background-color: white;
      width: 110px;
      height: 90px;
      border-radius: 15%;
      padding: 20px 10px;
      margin: 0 20px;
      display: inline-block;
      p {
        margin-top: 15px;
        color: #0096e6;
        font-weight: 600;
      }
    }

    .develop {
      background-color: white;
      width: 110px;
      height: 90px;
      border-radius: 15%;
      padding: 20px 10px;
      margin: 0 20px;
      display: inline-block;
      p {
        margin-top: 15px;
        color: #0096e6;
        font-weight: 600;
      }
    }

    .test {
      background-color: white;
      width: 110px;
      height: 90px;
      border-radius: 15%;
      padding: 20px 10px;
      margin: 0 20px;
      display: inline-block;
      p {
        margin-top: 15px;
        color: #0096e6;
        font-weight: 600;
      }
    }

    .launch {
      background-color: white;
      width: 110px;
      height: 90px;
      border-radius: 15%;
      padding: 20px 10px;
      margin: 0 20px;
      display: inline-block;
      p {
        margin-top: 15px;
        color: #0096e6;
        font-weight: 600;
      }
    }
  }

  .about-me {
    margin: 96px 0 80px;
    img {
      width: 330px;
    }
    h4 {
      font-weight: 500;
      position: relative;
      color: white;
      top: -55px;
      margin: 0 auto;
    }
    p {
      margin-top: 10px;
      max-width: 500px;
      margin: 0 auto;
      line-height: 25px;
      font-weight: 500;
    }
  }

  @media only screen and (max-width: 344px) {
    .ribbon img {
      width: 300px;
    }
    .ribbon p {
      top: -52px;
    }
    .work-ribbon img {
      width: 300px;
    }
    .work-ribbon p {
      top: -51px;
    }
    .about-me img {
      width: 300px;
    }
    .about-me p {
      top: -52px;
    }
  }

  @media only screen and (max-width: 518px) {
    .process {
      display: inline !important;
    }
    .process-2 {
      display: inline !important;
    }
  }

  @media only screen and (max-width: 507px) {
    .about-me p {
      padding: 0 20px;
    }
  }

  @media only screen and (max-width: 1059px) {
    .idea {
      margin-top: 30px !important;
    }
    .design {
      margin-top: 30px !important;
    }
    .develop {
      margin-top: 30px !important;
    }
    .test {
      margin-top: 30px !important;
    }
    .launch {
      margin-top: 30px !important;
    }
    .process {
      display: block;
    }
    .process-2 {
      display: block;
    }
  }
}

#portfolio {
  background-color: #e5dcc5;
  text-align: center;
  overflow: hidden;
  .works {
    margin-top: 80px;
    margin-bottom: 80px;
    h1 {
      color: #333333;
      font-size: 58px;
      font-weight: 700;
    }
  }

  .landing-title {
    margin-bottom: 50px;
    h2 {
      color: #333333;
      font-size: 38px;
      font-weight: 500;
      text-transform: capitalize;
    }
  }

  .landing-container {
    max-width: 1150px;
    width: 100%;
    margin: 0 auto;
    display: block;
    .landing-1 {
      width: 300px;
      height: 300px;
      background-color: white;
      display: inline-block;
      margin: 40px;
      .img-wrap {
        position: relative;
        width: 300px;
        .box-img {
          width: 300px;
          height: 201px;
          background-image: url('https://i.postimg.cc/nVN0M19c/Blankist-desktop.jpg');
          background-size: cover;
          background-position: center;
          transition: all 0.4s;
        }
        .text-wrapper {
          position: absolute;
          top: 75px;
          left: -16px;
          transition: all 0.4s;
          display: none;
          &:hover {
            display: block;
          }
          i {
            color: white;
            font-size: 50px;
            transition: all 3s;
          }
        }
      }
      .project-title {
        position: relative;
        text-align: center;
        bottom: 12px;
        text-decoration: none;
        h6 {
          margin-bottom: 10px;
          font-size: 16px;
          font-weight: 600;
          color: #334455;
          text-transform: capitalize;
        }
        p {
          font-size: 14px;
          color: #8b9495;
        }
      }
    }

    .landing-2 {
      width: 300px;
      height: 300px;
      background-color: white;
      display: inline-block;
      margin: 40px;
      .img-wrap {
        position: relative;
        width: 300px;
        .box-img {
          width: 300px;
          height: 201px;
          background-image: url('https://i.postimg.cc/268tTxMn/Landing-2.jpg');
          background-size: cover;
          background-position: center;
          transition: all 0.4s;
        }
        .text-wrapper {
          position: absolute;
          top: 75px;
          left: -16px;
          display: none;
          transition: all 0.4s;
          i {
            color: white;
            font-size: 50px;
            transition: all 3s;
          }
        }
      }
      .project-title {
        position: relative;
        text-align: center;
        bottom: 12px;
        text-decoration: none;
        h6 {
          margin-bottom: 10px;
          font-size: 16px;
          font-weight: 600;
          color: #334455;
          text-transform: capitalize;
        }
        p {
          font-size: 14px;
          color: #8b9495;
        }
      }
    }

    .landing-3 {
      width: 300px;
      height: 300px;
      background-color: white;
      display: inline-block;
      margin: 40px;
      .img-wrap {
        position: relative;
        width: 300px;
        .box-img {
          width: 300px;
          height: 201px;
          background-image: url('https://i.postimg.cc/PNsn5v6v/New-Roots-Cover.png');
          background-size: cover;
          background-position: center;
          transition: all 0.4s;
        }
        .text-wrapper {
          position: absolute;
          top: 75px;
          left: -16px;
          display: none;
          transition: all 0.4s;
          i {
            color: white;
            font-size: 50px;
            transition: all 3s;
          }
        }
      }
      .project-title {
        position: relative;
        text-align: center;
        bottom: 12px;
        text-decoration: none;
        h6 {
          margin-bottom: 10px;
          font-size: 16px;
          font-weight: 600;
          color: #334455;
          text-transform: capitalize;
        }
        p {
          font-size: 14px;
          color: #8b9495;
        }
      }
    }
  }

  .app-subtitle h3 {
    font-weight: 500 !important;
  }

  .app-container {
    width: 100%;
    text-align: center;
    display: inline;
    .landing-1 {
      width: 300px;
      height: 300px;
      background-color: white;
      display: inline-block;
      margin: 40px;
      .img-wrap {
        position: relative;
        width: 300px;
        .box-img {
          width: 300px;
          height: 201px;
          background-image: url('https://i.postimg.cc/4dvFvSBd/Comfy-store1.png');
          background-size: cover;
          background-position: center;
          transition: all 0.4s;
        }
        .text-wrapper {
          position: absolute;
          top: 75px;
          left: -16px;
          transition: all 0.4s;
          display: none;
          &:hover {
            display: block;
          }
          i {
            color: white;
            font-size: 50px;
            transition: all 3s;
          }
        }
      }
      .project-title {
        position: relative;
        text-align: center;
        bottom: 12px;
        text-decoration: none;
        h6 {
          margin-bottom: 10px;
          font-size: 16px;
          font-weight: 600;
          color: #334455;
          text-transform: capitalize;
        }
        p {
          font-size: 14px;
          color: #8b9495;
        }
      }
    }

    .landing-2 {
      width: 300px;
      height: 300px;
      background-color: white;
      display: inline-block;
      margin: 40px;
      .img-wrap {
        position: relative;
        width: 300px;
        .box-img {
          width: 300px;
          height: 201px;
          background-image: url('https://i.postimg.cc/fTPbXJjK/mapty.jpg');
          background-size: cover;
          background-position: center;
          transition: all 0.4s;
        }
        .text-wrapper {
          position: absolute;
          top: 75px;
          left: -16px;
          display: none;
          transition: all 0.4s;
          i {
            color: white;
            font-size: 50px;
            transition: all 3s;
          }
        }
      }
      .project-title {
        position: relative;
        text-align: center;
        bottom: 12px;
        text-decoration: none;
        h6 {
          margin-bottom: 10px;
          font-size: 16px;
          font-weight: 600;
          color: #334455;
          text-transform: capitalize;
        }
        p {
          font-size: 14px;
          color: #8b9495;
        }
      }
    }

    .landing-3 {
      width: 300px;
      height: 300px;
      background-color: white;
      display: inline-block;
      margin: 40px;
      .img-wrap {
        position: relative;
        width: 300px;
        .box-img {
          width: 300px;
          height: 201px;
          background-image: url('https://i.postimg.cc/vBPhMpC3/Forkify-App.jpg');
          background-size: cover;
          background-position: center;
          transition: all 0.4s;
        }
        .text-wrapper {
          position: absolute;
          top: 75px;
          left: -16px;
          display: none;
          transition: all 0.4s;
          i {
            color: white;
            font-size: 50px;
            transition: all 3s;
          }
        }
      }
      .project-title {
        position: relative;
        text-align: center;
        bottom: 12px;
        text-decoration: none;
        h6 {
          margin-bottom: 10px;
          font-size: 16px;
          font-weight: 600;
          color: #334455;
          text-transform: capitalize;
        }
        p {
          font-size: 14px;
          color: #8b9495;
        }
      }
    }
  }

  .app-title,
  .email-title {
    margin: 90px 0 50px;
    h2 {
      color: #333333;
      font-size: 38px;
      font-weight: 500;
      text-transform: capitalize;
    }
  }

  .email-container {
    width: 100%;
    text-align: center;
    display: inline;
    .email-1 {
      width: 300px;
      height: 300px;
      background-color: white;
      display: inline-block;
      margin: 40px;
      .img-wrap {
        position: relative;
        width: 300px;
        .box-img {
          width: 300px;
          height: 201px;
          background-image: url('https://i.postimg.cc/T18NPcHD/adobe.jpg');
          background-size: cover;
          background-position: center;
          transition: all 0.4s;
        }
        .text-wrapper {
          position: absolute;
          top: 75px;
          left: -16px;
          display: none;
          transition: all 0.4s;
          i {
            color: white;
            font-size: 50px;
            transition: all 3s;
          }
        }
      }
      .project-title {
        position: relative;
        text-align: center;
        bottom: 12px;
        text-decoration: none;
        h6 {
          margin-bottom: 10px;
          font-size: 16px;
          font-weight: 600;
          color: #334455;
          text-transform: capitalize;
        }
        p {
          font-size: 14px;
          color: #8b9495;
        }
      }
    }

    .email-2 {
      width: 300px;
      height: 300px;
      background-color: white;
      display: inline-block;
      margin: 40px;
      .img-wrap {
        position: relative;
        width: 300px;
        .box-img {
          width: 300px;
          height: 201px;
          background-image: url('https://i.postimg.cc/dQdSxzLm/Apple.jpg');
          background-size: cover;
          background-position: center;
          transition: all 0.4s;
        }
        .text-wrapper {
          position: absolute;
          top: 75px;
          left: -16px;
          display: none;
          transition: all 0.4s;
          i {
            color: white;
            font-size: 50px;
            transition: all 3s;
          }
        }
      }
      .project-title {
        position: relative;
        text-align: center;
        bottom: 12px;
        text-decoration: none;
        h6 {
          margin-bottom: 10px;
          font-size: 16px;
          font-weight: 600;
          color: #334455;
          text-transform: capitalize;
        }
        p {
          font-size: 14px;
          color: #8b9495;
        }
      }
    }

    .email-3 {
      width: 300px;
      height: 300px;
      background-color: white;
      display: inline-block;
      margin: 40px;
      .img-wrap {
        position: relative;
        width: 300px;
        .box-img {
          width: 300px;
          height: 201px;
          background-image: url('https://i.postimg.cc/2y4cLBPX/chipotle.jpg');
          background-size: cover;
          background-position: center;
          transition: all 0.4s;
        }
        .text-wrapper {
          position: absolute;
          top: 75px;
          left: -16px;
          display: none;
          transition: all 0.4s;
          i {
            color: white;
            font-size: 50px;
            transition: all 3s;
          }
        }
      }
      .project-title {
        position: relative;
        text-align: center;
        bottom: 12px;
        text-decoration: none;
        h6 {
          margin-bottom: 10px;
          font-size: 16px;
          font-weight: 600;
          color: #334455;
          text-transform: capitalize;
        }
        p {
          font-size: 14px;
          color: #8b9495;
        }
      }
    }
  }

  .email-container-2 {
    width: 100%;
    text-align: center;
    display: inline;

  }

  .img-wrap:hover .box-img {
    filter: brightness(90%);
  }

  .img-wrap:hover .text-wrapper {
    display: block;
  }

  .text-wrapper i {
    color: white;
    font-size: 50px;
    transition: all 3s;
  }

  .img-wrap:hover .text-wrapper-2 {
    display: block;
  }

  @media only screen and (max-width: 764px) {
    .email-container-2 {
      margin: 0 auto;
      text-align: center !important;
    }
    .email-3 {
      margin: 40px !important;
    }
  }

  @media only screen and (max-width: 1148px) {
    .email-container {
      display: block;
    }
    .email-container-2 {
      display: block;
      max-width: 680px;
      margin: 0 auto;
      text-align: left !important;
      .email-3 {
        margin: 40px 40px 40px 0;
      }
    }
  }
}

/*-----------Individual Project Preview Pages-----------*/

#egypt {
  max-width: 1100px;
  margin: 0 auto;
  text-align: center;

  .exit {
    margin-top: 30px;
    :hover {
      background-color: #fec84f;
      border: none;
    }
    a {
      text-decoration: none;
      color: #333333;
      height: 50px;
      width: 50px;
      padding: 6px 10px;
      border-radius: 50%;
      border: solid 1px #d6d6d6;
      transition: all 0.2s ease-in-out;
    }
  }

  img {
    width: 100%;
  }
  .project-description {
    margin: 80px 0 30px;
    color: #333333;
    margin-bottom: 60px;
    h4 {
      font-size: 16px;
      font-weight: 700;
    }
    p {
      max-width: 800px;
      margin: 0 auto;
      font-size: 16px;
      font-weight: 500;
    }
  }

  .launch-btn {
    font-family: 'Montserrat';
    font-weight: 600;
    text-align: center;
    margin: 60px 0 0;
    :hover {
      background: #fec85f;
      color: #fefefe;
      border: none;
      transition: all 0.2s ease-in-out;
    }
    a {
      text-decoration: none;
      border: solid 2px black;
      padding: 15px 35px;
      color: black;
      transition: all 0.2s ease-in-out;
      margin-bottom: 30px;
    }
  }

  .code-btn {
    font-family: 'Montserrat';
    font-weight: 600;
    text-align: center;
    margin: 60px 0 80px;
    :hover {
      background: #fec85f;
      color: #fefefe;
      border: none;
      transition: all 0.2s ease-in-out;
    }
    a {
      text-decoration: none;
      border: solid 2px black;
      padding: 15px 35px;
      color: black;
      transition: all 0.2s ease-in-out;
    }
  }

  .exit-2 {
    margin: 0px 0 30px;
    :hover {
      background-color: #fec84f;
      border: none;
    }
    a {
      text-decoration: none;
      color: #333333;
      height: 50px;
      width: 50px;
      padding: 6px 10px;
      border-radius: 50%;
      border: solid 1px #d6d6d6;
      transition: all 0.2s ease-in-out;
    }
  }
}

#beats {
  max-width: 1100px;
  margin: 0 auto;
  text-align: center;

  .exit {
    margin-top: 30px;
    :hover {
      background-color: #fec84f;
      border: none;
    }
    a {
      text-decoration: none;
      color: #333333;
      height: 50px;
      width: 50px;
      padding: 6px 10px;
      border-radius: 50%;
      border: solid 1px #d6d6d6;
      transition: all 0.2s ease-in-out;
    }
  }

  img {
    width: 100%;
  }
  .project-description {
    margin: 80px 0 30px;
    color: #333333;
    margin-bottom: 60px;
    h4 {
      font-size: 16px;
      font-weight: 700;
    }
    p {
      max-width: 800px;
      margin: 0 auto;
      font-size: 16px;
      font-weight: 500;
    }
  }

  .launch-btn {
    font-family: 'Montserrat';
    font-weight: 600;
    text-align: center;
    margin: 60px 0 0;
    :hover {
      background: #fec85f;
      color: #fefefe;
      border: none;
      transition: all 0.2s ease-in-out;
    }
    a {
      text-decoration: none;
      border: solid 2px black;
      padding: 15px 35px;
      color: black;
      transition: all 0.2s ease-in-out;
      margin-bottom: 30px;
    }
  }

  .code-btn {
    font-family: 'Montserrat';
    font-weight: 600;
    text-align: center;
    margin: 60px 0 80px;
    :hover {
      background: #fec85f;
      color: #fefefe;
      border: none;
      transition: all 0.2s ease-in-out;
    }
    a {
      text-decoration: none;
      border: solid 2px black;
      padding: 15px 35px;
      color: black;
      transition: all 0.2s ease-in-out;
    }
  }

  .exit-2 {
    margin: 0px 0 30px;
    :hover {
      background-color: #fec84f;
      border: none;
    }
    a {
      text-decoration: none;
      color: #333333;
      height: 50px;
      width: 50px;
      padding: 6px 10px;
      border-radius: 50%;
      border: solid 1px #d6d6d6;
      transition: all 0.2s ease-in-out;
    }
  }
}

#adobe {
  max-width: 1100px;
  margin: 0 auto;
  text-align: center;

  .exit {
    margin-top: 30px;
    :hover {
      background-color: #fec84f;
      border: none;
    }
    a {
      text-decoration: none;
      color: #333333;
      height: 50px;
      width: 50px;
      padding: 6px 10px;
      border-radius: 50%;
      border: solid 1px #d6d6d6;
      transition: all 0.2s ease-in-out;
    }
  }

  img {
    width: 100%;
  }
  .project-description {
    margin: 80px 0 30px;
    color: #333333;
    margin-bottom: 60px;
    h4 {
      font-size: 16px;
      font-weight: 700;
    }
    p {
      font-size: 16px;
      font-weight: 500;
    }
  }

  .launch-btn {
    font-family: 'Montserrat';
    font-weight: 600;
    text-align: center;
    margin: 60px 0 0;
    :hover {
      background: #fec85f;
      color: #fefefe;
      border: none;
      transition: all 0.2s ease-in-out;
    }
    a {
      text-decoration: none;
      border: solid 2px black;
      padding: 15px 35px;
      color: black;
      transition: all 0.2s ease-in-out;
      margin-bottom: 30px;
    }
  }

  .code-btn {
    font-family: 'Montserrat';
    font-weight: 600;
    text-align: center;
    margin: 60px 0 80px;
    :hover {
      background: #fec85f;
      color: #fefefe;
      border: none;
      transition: all 0.2s ease-in-out;
    }
    a {
      text-decoration: none;
      border: solid 2px black;
      padding: 15px 35px;
      color: black;
      transition: all 0.2s ease-in-out;
    }
  }

  .exit-2 {
    margin: 0px 0 30px;
    :hover {
      background-color: #fec84f;
      border: none;
    }
    a {
      text-decoration: none;
      color: #333333;
      height: 50px;
      width: 50px;
      padding: 6px 10px;
      border-radius: 50%;
      border: solid 1px #d6d6d6;
      transition: all 0.2s ease-in-out;
    }
  }
}

#apple {
  max-width: 1100px;
  margin: 0 auto;
  text-align: center;

  .exit {
    margin-top: 30px;
    :hover {
      background-color: #fec84f;
      border: none;
    }
    a {
      text-decoration: none;
      color: #333333;
      height: 50px;
      width: 50px;
      padding: 6px 10px;
      border-radius: 50%;
      border: solid 1px #d6d6d6;
      transition: all 0.2s ease-in-out;
    }
  }

  img {
    width: 100%;
  }
  .project-description {
    margin: 80px 0 30px;
    color: #333333;
    margin-bottom: 60px;
    h4 {
      font-size: 16px;
      font-weight: 700;
    }
    p {
      font-size: 16px;
      font-weight: 500;
    }
  }

  .launch-btn {
    font-family: 'Montserrat';
    font-weight: 600;
    text-align: center;
    margin: 60px 0 0;
    :hover {
      background: #fec85f;
      color: #fefefe;
      border: none;
      transition: all 0.2s ease-in-out;
    }
    a {
      text-decoration: none;
      border: solid 2px black;
      padding: 15px 35px;
      color: black;
      transition: all 0.2s ease-in-out;
      margin-bottom: 30px;
    }
  }

  .code-btn {
    font-family: 'Montserrat';
    font-weight: 600;
    text-align: center;
    margin: 60px 0 80px;
    :hover {
      background: #fec85f;
      color: #fefefe;
      border: none;
      transition: all 0.2s ease-in-out;
    }
    a {
      text-decoration: none;
      border: solid 2px black;
      padding: 15px 35px;
      color: black;
      transition: all 0.2s ease-in-out;
    }
  }

  .exit-2 {
    margin: 0px 0 30px;
    :hover {
      background-color: #fec84f;
      border: none;
    }
    a {
      text-decoration: none;
      color: #333333;
      height: 50px;
      width: 50px;
      padding: 6px 10px;
      border-radius: 50%;
      border: solid 1px #d6d6d6;
      transition: all 0.2s ease-in-out;
    }
  }
}

#chipotle {
  max-width: 1100px;
  margin: 0 auto;
  text-align: center;

  .exit {
    margin-top: 30px;
    :hover {
      background-color: #fec84f;
      border: none;
    }
    a {
      text-decoration: none;
      color: #333333;
      height: 50px;
      width: 50px;
      padding: 6px 10px;
      border-radius: 50%;
      border: solid 1px #d6d6d6;
      transition: all 0.2s ease-in-out;
    }
  }

  img {
    width: 100%;
  }
  .project-description {
    margin: 80px 0 30px;
    color: #333333;
    margin-bottom: 60px;
    h4 {
      font-size: 16px;
      font-weight: 700;
    }
    p {
      font-size: 16px;
      font-weight: 500;
    }
  }

  .launch-btn {
    font-family: 'Montserrat';
    font-weight: 600;
    text-align: center;
    margin: 60px 0 0;
    :hover {
      background: #fec85f;
      color: #fefefe;
      border: none;
      transition: all 0.2s ease-in-out;
    }
    a {
      text-decoration: none;
      border: solid 2px black;
      padding: 15px 35px;
      color: black;
      transition: all 0.2s ease-in-out;
      margin-bottom: 30px;
    }
  }

  .code-btn {
    font-family: 'Montserrat';
    font-weight: 600;
    text-align: center;
    margin: 60px 0 80px;
    :hover {
      background: #fec85f;
      color: #fefefe;
      border: none;
      transition: all 0.2s ease-in-out;
    }
    a {
      text-decoration: none;
      border: solid 2px black;
      padding: 15px 35px;
      color: black;
      transition: all 0.2s ease-in-out;
    }
  }

  .exit-2 {
    margin: 0px 0 30px;
    :hover {
      background-color: #fec84f;
      border: none;
    }
    a {
      text-decoration: none;
      color: #333333;
      height: 50px;
      width: 50px;
      padding: 6px 10px;
      border-radius: 50%;
      border: solid 1px #d6d6d6;
      transition: all 0.2s ease-in-out;
    }
  }
}
